<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <AlertSnackbar v-if="attribute == 0 && answerData.status < 3" :disp="!isBetweenDate(dest.ans_period_from, dest.and_period_to)" color="warning" message="回答受付期間外のため、回答できません"></AlertSnackbar>
      <AlertSnackbar v-else :disp="answerData.status >= 3" color="accent" message="回答は送信済みです"></AlertSnackbar>
      <v-row>
        <v-col>
          <h2>{{officeData.companyName}}様 {{officeData.officeName}} 実態調査</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn 
            class="btn_font primary mr-2"
            width="150"
            v-on:click="showImport()"
            :disabled="loading || ((attribute == 0) && (answerData.status >= 3))"
          >回答票インポート</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center" cols="4" sm="4" md="4" lg="4">
          <DatePicker
            label="回答受付期間 開始"
            v-model="dest.ans_period_from"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="attribute == 0 || !editAnsPeriodMode"
            :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
          ～
          <DatePicker
            label="回答受付期間 終了"
            v-model="dest.and_period_to"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="attribute == 0 || !editAnsPeriodMode"
            :rules="[checkStartEndDate(dest.ans_period_from, dest.and_period_to)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
        </v-col>
        <v-col v-if="attribute != 0" cols="auto">
          <v-btn v-if="!editAnsPeriodMode" class="btn_font primary" width="120" v-on:click="editAnsPeriod()" :disabled="loading" >受付期間変更</v-btn>
          <v-btn v-else class="btn_font primary" width="120" v-on:click="registAnsPeriod()" :disabled="loading" >保存</v-btn>
          <v-btn v-if="editAnsPeriodMode" class="ml-5 btn_font primary" width="120" v-on:click="cancelAnsPeriod()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>

      <v-row id="ansAreaTop">
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-alert
            type="info"
            border="left"
            colored-border
            elevation="1"
          >
            <ul>
              <li>①事業所単位でご回答をお願いします。</li>
              <li>②直近の本決算期（12ヶ月分）の数値をご記入ください｡</li>
              <li>③営業冷蔵倉庫の実態調査ですので、すべての質問項目で自家用分（ただし社内売上を計上している場合は算入）および再保管分は除外してください。</li>
              <li>④<span class="text-error">製氷事業・自社での運送事業等の兼業分は除外</span>してください。</li>
              <li>⑤緑色の箇所に入力（記入）してください。合計は自動計算されますので入力は不要です。</li>
              <li>⑥該当がない場合は空欄にせず、かならず「０」（ゼロ）をご記入ください。</li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" sm="2" md="2" lg="2">
          <v-text-field
            label="会員番号"
            v-model="answerData.memberNo"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="4" sm="4" md="4" lg="4">
        </v-col>
        <v-col class="d-flex align-center" cols="6" sm="6" md="6" lg="6">
          <DatePicker
            label="調査対象期間 開始"
            v-model="answerData.targetMonthFrom"
            format="YYYY/M"
            selectType="month"
            :readonly="attribute == 0 && answerData.status >= 3"
            :rules="[requiredIf_ns(() => saveRegist), checkStartEndDate(answerData.targetMonthFrom, answerData.targetMonthTo)]"
            :clearable="false"
            :loading="loading"
            backgroundColor="input"
          ></DatePicker>
          ～
          <DatePicker
            label="調査対象期間 終了"
            v-model="answerData.targetMonthTo"
            format="YYYY/M"
            selectType="month"
            :readonly="attribute == 0 && answerData.status >= 3"
            :rules="[requiredIf_ns(() => saveRegist), checkStartEndDate(answerData.targetMonthFrom, answerData.targetMonthTo)]"
            :clearable="false"
            :loading="loading"
            backgroundColor="input"
          ></DatePicker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6">
          <v-text-field
            label="会社名"
            v-model="answerData.companyName"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6">
          <v-text-field
            label="事業所名"
            v-model="answerData.officeName"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-text-field
            label="所在地"
            v-model="answerData.address"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>記入内容に関する問い合わせ先</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
            <v-text-field
              label="部署名"
              v-model="answerData.departmentName"
              dense
              :readonly="attribute == 0 && answerData.status >= 3"
              :clearable="false"
              :rules="[requiredIf_ns(() => saveRegist)]"
              :maxlength="50"
              :counter="50"
              :loading="loading"
              background-color="input"
            ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field
            label="担当者名"
            v-model="answerData.responderName"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[requiredIf_ns(() => saveRegist)]"
            :maxlength="50"
            :counter="50"
            :loading="loading"
            background-color="input"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field
            label="電話"
            v-model="answerData.tel"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[requiredIf_ns(() => saveRegist)]"
            :maxlength="50"
            :counter="50"
            :loading="loading"
            background-color="input"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field
            label="メール"
            v-model="answerData.email"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[requiredIf_ns(() => saveRegist), validEmail]"
            :maxlength="100"
            :counter="100"
            :loading="loading"
            background-color="input"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- １．庫腹・受給 -->
      <v-row>
        <v-col>
          <h3>１．庫腹・受給【 設備能力の増減や貨物動態、在庫率・貨物回転数などの算出に使用します 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                (2)、(3)の容積建で荷役料の収受がなければ「0」（ゼロ)を記入してください。<br>
                (2)(3)(4)(5) について､ｱｲｽｸﾘｰﾑ等の変則容積建契約に関しては､一般保管の欄に、重量換算した数値を記入してください。<br>
                (4)(5)は月末在庫、中間在庫の12ヶ月分の平均を記入してください。 年間累計値÷12ヵ月＝入力値
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :items="answerData.capacityReceipt"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{items}">
              <thead>
                <tr>
                  <th :width="100"></th>
                  <th :width="150" class="text-center">項目</th>
                  <th :width="100" class="text-center">単位</th>
                  <th class="text-center">うち一般保管</th>
                  <th class="text-center">うち容積建</th>
                  <th class="text-center">合計</th>
                </tr>
              </thead>
              <tbody>
                <!-- 所管容積 -->
                <tr>
                  <td class="center">
                    (1)
                  </td>
                  <td class="">
                    所管容積
                  </td>
                  <td class="center">
                    ㎥
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="volumeValueGeneral"
                      dense
                      :readonly="true"
                      :rules="[minValLocaleJP(0)]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <v-row>
                      <v-col cols="4" class="pr-1">
                        <NumberTextField
                          v-model="items[0].total"
                          dense
                          :readonly="true"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                        ></NumberTextField>
                      </v-col>
                      <v-col cols="8" class="pl-1">
                        <NumberTextField
                          :value="facilityTon"
                          dense
                          :readonly="true"
                          :clearable="false"
                          :rules="[]"
                          :loading="loading"
                          prefix="("
                          suffix="設備トン)"
                        ></NumberTextField>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <!-- 年間入庫数量 -->
                <tr>
                  <td class="center">
                    (2)
                  </td>
                  <td class="">
                    年間入庫数量
                  </td>
                  <td class="center">
                    トン
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => saveRegist), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="inValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                      suffix="(年計)"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間出庫数量 -->
                <tr>
                  <td class="center">
                    (3)
                  </td>
                  <td class="">
                    年間出庫数量
                  </td>
                  <td class="center">
                    トン
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => saveRegist), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="outValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                      suffix="(年計)"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 前月繰越数量 -->
                <tr>
                  <td class="center">
                    (4)
                  </td>
                  <td class="">
                    前月繰越数量
                  </td>
                  <td class="center">
                    トン
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => saveRegist), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="prevTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 中間繰越数量 -->
                <tr>
                  <td class="center">
                    (5)
                  </td>
                  <td class="">
                    中間繰越数量
                  </td>
                  <td class="center">
                    トン
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[4].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => saveRegist), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="interTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- ２．売上 -->
      <v-row>
        <v-col>
          <h3>２．売上【 設備トン収入などの算出に使用します 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                (6) の容積建の欄には､容積建保管料を記入してください｡

                <v-data-table
                  :loading="loading"
                  :dense='true'
                  :items-per-page="10"
                  :mobile-breakpoint="0"
                  :disable-pagination='true'
                  :hide-default-header='true'
                  :hide-default-footer='true'
                  :fixed-header='true'
                  disable-sort
                  locale="ja-jp"
                  loading-text="読込中"
                  no-data-text="データがありません。"
                  class="no-hover-effect table-border mt-5 mb-5"
                >
                  <template v-slot:body="{}">
                    <tbody>
                      <tr>
                        <td class="center td-rowspan" rowspan="5" :width="200">
                          人員欄に人数記載
                        </td>
                        <td class="left" :width="200">
                          荷役料
                        </td>
                        <td class="left">
                          入出庫料
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          その他作業料
                        </td>
                        <td class="left">
                          選別、仕分け、切付、検貫、検品、デバンニング、バンニング、はい替、積替、バンド切等の作業料
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          その他手数料
                        </td>
                        <td class="left">
                          名変、在庫証明書等の手数料
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          流通加工
                        </td>
                        <td class="left">
                          ラベル貼、箱詰、開梱、送り状貼、バンド掛等
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          利用運送
                        </td>
                        <td class="left">
                          部門が明確に分かれている場合は運送業とみなして除外する
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>

                <v-data-table
                  :loading="loading"
                  :dense='true'
                  :items-per-page="10"
                  :mobile-breakpoint="0"
                  :disable-pagination='true'
                  :hide-default-header='true'
                  :hide-default-footer='true'
                  :fixed-header='true'
                  disable-sort
                  locale="ja-jp"
                  loading-text="読込中"
                  no-data-text="データがありません。"
                  class="no-hover-effect table-border-primary"
                >
                  <template v-slot:body="{}">
                    <tbody>
                      <tr>
                        <td class="center td-rowspan" rowspan="3" :width="200">
                          除外
                        </td>
                        <td class="left" :width="200">
                          食品加工
                        </td>
                        <td class="left">
                          食品が露出(外気に触れる)する状態がある加工
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          運送業
                        </td>
                        <td class="left">
                        </td>
                      </tr>
                      <tr>
                        <td class="left">
                          通関業
                        </td>
                        <td class="left">
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="pb-0 mb-0">
        <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 mb-0">
          貴社該当の事業に ✔印 をお願いします。
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0 mb-0">
        <v-col class="pt-0 mt-0 pb-0 d-flex align-center greenBack" cols="12" sm="12" md="12" lg="12">
          <v-checkbox 
            label="営業冷蔵倉庫"
            v-model="answerData.industry"
            dense
            value="1"
            :loading="loading"
            class="mr-5"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-checkbox 
            label="水産加工/食品加工業"
            v-model="answerData.industry"
            dense
            value="2"
            :loading="loading"
            class="mr-5"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-checkbox 
            label="製氷業"
            v-model="answerData.industry"
            dense
            value="3"
            :loading="loading"
            class="mr-5"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-checkbox 
            label="運送業"
            v-model="answerData.industry"
            dense
            value="4"
            :loading="loading"
            class="mr-5"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-checkbox 
            label="食品/物品販売業"
            v-model="answerData.industry"
            dense
            value="5"
            :loading="loading"
            class="mr-5"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-checkbox 
            label="その他"
            v-model="answerData.industry"
            dense
            value="6"
            :loading="loading"
            class="mr-2"
            :readonly="attribute == 0 && answerData.status >= 3"
          />
          <v-text-field
            v-model="answerData.industryOther"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :disabled="!answerData.industry.some(element => element == 6)"
            :rules="[]"
            :maxlength="100"
            :loading="loading"
            prefix="("
            suffix=")"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 mt-0 text-right">
          冷蔵倉庫以外の事業についての収入、人員については、<span class="text-error">以降ご回答に含めないで</span>下さい。
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :items="answerData.sales"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{items}">
              <thead>
                <tr>
                  <th :width="100"></th>
                  <th class="text-center" colspan="2">項目</th>
                  <th class="text-center">単位</th>
                  <th class="text-center">うち一般保管</th>
                  <th class="text-center">うち容積建</th>
                  <th class="text-center">合計</th>
                </tr>
              </thead>
              <tbody>
                <!-- 年間保管料収入 -->
                <tr>
                  <td class="center">
                    (6)
                  </td>
                  <td class="center" rowspan="7">
                    消費税除く
                  </td>
                  <td class="center">
                    年間保管料収入
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="storageValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間荷役料収入 -->
                <tr>
                  <td class="center">
                    (7)
                  </td>
                  <td class="center">
                    年間荷役料収入
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="cargoValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間その他作業料 -->
                <tr>
                  <td class="center">
                    (8)
                  </td>
                  <td class="center">
                    年間その他作業料
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="workValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間その他手数料 -->
                <tr>
                  <td class="center">
                    (9)
                  </td>
                  <td class="center">
                    年間その他手数料
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="chargeValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間凍結料収入 -->
                <tr>
                  <td class="center" rowspan="3">
                    (10)
                  </td>
                  <td class="center">
                    年間凍結料収入
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[4].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center disablecolor">
                  </td>
                </tr>
                <!-- 年間流通加工(粗利) -->
                <tr>
                  <td class="center">
                    年間流通加工<span class="text-error">(粗利)</span><br>
                    <span class="text-caption text-error">粗利=売上高-外注費</span>
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[5].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[5].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="processingValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 年間利用運送(粗利) -->
                <tr>
                  <td class="center">
                    年間利用運送<span class="text-error">(粗利)</span><br>
                    <span class="text-caption text-error">粗利=売上高-外注費</span>
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[6].valueGeneral"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[6].valueMeasure"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="transportValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 収入合計 -->
                <tr>
                  <td class="center">
                    (11)
                  </td>
                  <td class="center" colspan="2">
                    収入合計
                  </td>
                  <td class="center">
                    千円
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="incomeValueGeneral"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="incomeValueMeasure"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="incomeValueTotal"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- ３．人員・賃金 -->
      <v-row>
        <v-col>
          <h3>３．人員・賃金【 平均年収、人員数、生産性指標などの算出に使用します 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                保管・荷役・流通加工のみの人員・賃金情報についてお答え下さい。<br>
                <span class="text-error">※水産加工・製氷等、兼業をされている場合でも、業務量に応じて按分した「冷蔵倉庫業」についての値をお答え下さい。</span><br>
                (12)～(16)従業員数は小数点以下第1位まで記入してください。<span class="text-error">倉庫業務に従事した者だけ（流通加工含む）記入</span>し、製氷等の兼業をされている場合は、業務量に応じて、按分して記入して下さい。<br>
                ・ｱﾙﾊﾞｲト人数の1日当たりの平均が算出できない場合は、年間延べ人数を240(日)で割ってください。<br>
                ・平均年収は、基準内賃金+基準外賃金(所定時間外・休日出勤･年間賞与)を含み、通勤費は不算入でお願いします。<br>
                (18) 日々の下請・派遣の平均人数を記入してください。<br>
                本船荷役・コンテナなどの荷降ろし作業員に関して、１日当たりの平均人数が算出できない場合は、１人１日"30トン扱う”として、算出して下さい。
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :items="answerData.staffWages.company"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{items}">
              <thead>
                <tr>
                  <th :width="100"></th>
                  <th class="text-center">項目</th>
                  <th class="text-center">対象</th>
                  <th class="text-center">人数(1日あたり)</th>
                  <th class="text-center">平均年収(千円)</th>
                  <th class="text-center">平均年齢</th>
                  <th class="text-center">平均勤続年数</th>
                </tr>
              </thead>
              <tbody>
                <!-- 作業専任職 パート・アルバイト -->
                <tr>
                  <td class="center">
                    (12)
                  </td>
                  <td class="center" rowspan="2">
                    自社従業員数・<br>平均年収(作業専任職)
                  </td>
                  <td class="">
                    パート・アルバイト
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color="input"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center disablecolor">
                  </td>
                </tr>
                <!-- 作業専任職 -->
                <tr>
                  <td class="center">
                    (13)
                  </td>
                  <td class="">
                    作業専任職
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].aveAge"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].aveContinuous"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 事務職 パート・アルバイト -->
                <tr>
                  <td class="center">
                    (14)
                  </td>
                  <td class="center" rowspan="3">
                    自社従業員数・<br>平均年収(事務職)
                  </td>
                  <td class="">
                    パート・アルバイト
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[2].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center disablecolor">
                  </td>
                </tr>
                <!-- 事務職 -->
                <tr>
                  <td class="center">
                    (15)
                  </td>
                  <td class="">
                    事務職
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].aveAge"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[3].aveContinuous"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 管理職・役員・その他 -->
                <tr>
                  <td class="center">
                    (16)
                  </td>
                  <td class="">
                    管理職・役員・その他
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[4].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[4].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center disablecolor">
                  </td>
                </tr>
                <!-- 合計 -->
                <tr>
                  <td class="center">
                    (17)
                  </td>
                  <td class="center" colspan="2">
                    合計
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="employeeTotalNum"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="employeeTotalAveIncome"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                    ></NumberTextField>
                  </td>
                  <td class="center disablecolor">
                  </td>
                  <td class="center disablecolor">
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :items="answerData.staffWages.partner"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{items}">
              <thead>
                <tr>
                  <th :width="100"></th>
                  <th class="text-center">項目</th>
                  <th class="text-center">対象</th>
                  <th class="text-center">人数(1日あたり)</th>
                  <th class="text-center">平均日給(円)</th>
                </tr>
              </thead>
              <tbody>
                <!-- 下請・派遣現場要員 -->
                <tr>
                  <td class="center">
                    (18)
                  </td>
                  <td class="center td-rowspan" rowspan="2">
                    協力会社<br>従業員数
                  </td>
                  <td class="">
                    下請・派遣現場要員
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color="input"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[0].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                </tr>
                <!-- 下請・派遣事務職 -->
                <tr>
                  <td class="center">
                    (19)
                  </td>
                  <td class="">
                    下請・派遣事務職
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].numPerDay"
                      dense
                      :orgRoundStep="10" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="items[1].aveIncome"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(99999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- ４．業務損益 -->
      <v-row>
        <v-col>
          <h3>４．業務損益【 冷蔵倉庫業の経営状況調べです 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                ※（22）<span class="text-error">業務損益＝経常損益＋ 減価償却費＋支払利息＋借庫料  (別紙参照)</span><br>
                <span class="text-error">業務損益とは、経常損益に減価償却費・金利・借庫料の費用を算入前の損益としてご回答ください。</span><br>
                事業所単位の計算が難しい場合は全社の業務損益を所管容積で按分して算出してください。
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(20)業務損益(年間)"
            v-model="answerData.profitAndLoss"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
            :loading="loading"
            background-color='input'
            suffix="千円"
          ></NumberTextField>
        </v-col>
      </v-row>

      <!-- ５．その他-1 -->
      <v-row>
        <v-col>
          <h3>５．その他-1【 電力有効率、電力料(KWH単価)動向 及び 設備要件の調査です 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                (21) 途中で契約電力が変更になった場合は、平均を記入してください。<br>
                (24)<span class="text-error">ﾄﾞｯｸｼｪﾙﾀｰ､ｵｰﾌﾟﾝﾃﾞｯｷ等、仕様に関わらずﾄﾗｯｸ接車可能台数をご入力下さい｡</span><br>
                (25) では○、×を選んでください。<br>
                (26) で低温化済を選んだ場合は、１階荷捌室の設定温度を記入してください。<br>
                (27)、(28)では名義変更のみの取引先はカウントしないでください。
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(21)契約電力"
            v-model="answerData.contractElectricity"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="kw"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="今回ご回答"
            v-model="unitPriceElectricity"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='attention'
            prefix="KWH単価"
            suffix="円/kwh"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="前年平均"
            v-model="previous.previousUnitPriceElectricity"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='info'
            suffix="円/kwh"
            :propClass="['input-color-primary']"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <span class="text-caption">(23)×1000÷(22)</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(22)年間電力使用量"
            v-model="answerData.usageElectricity"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(99999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="kwh"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="今回ご回答"
            v-model="rateElectricity"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='attention'
            prefix="契約電力有効率"
            suffix="%"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="前年平均"
            v-model="previous.previousRateElectricity"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='info'
            suffix="%"
            :propClass="['input-color-primary']"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <span class="text-caption">電力使用量÷（契約電力×24h×365日）×100</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6">
          <NumberTextField
            label="(23)年間電力支払金額"
            v-model="answerData.paymentElectricity"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="千円(消費税除く)"
          ></NumberTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(24)トラック接車可能台数"
            v-model="answerData.numDock"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="台分"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-select
            label="(25)１階荷捌室低温化"
            v-model="answerData.refrigerator"
            :items="[
              { text: '×', value: 0 },
              { text: '○', value: 1 },
            ]"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[]"
            :loading="loading"
            background-color='input'
            @change="$refs.form.validate()"
          ></v-select>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(26)１階荷捌設定温度"
            v-model="answerData.refrigeratorTemperature"
            dense
            :orgRoundStep="10" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :disabled="!answerData.refrigerator"
            :clearable="false"
            :rules="[requiredIf(() => (answerData.refrigerator)), maxValLocaleJP(9999999), minValLocaleJP(-9999999)]"
            :loading="loading"
            background-color='input'
            suffix="℃"
          ></NumberTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(27)調査時点での取引荷主企業数"
            v-model="answerData.numClient"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="社"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(28)調査時点での過去１年間の新規取引荷主企業数"
            v-model="answerData.numNewClient"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="社"
          ></NumberTextField>
        </v-col>
      </v-row>

      <!-- ５．その他-2 -->
      <v-row>
        <v-col>
          <h3>５．その他-2【貨物比重、嵩高貨物の傾向調査、及び パレット規格についての調査です 】</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                記入要領
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                (29)は冷蔵庫内に入っている使用パレット枚数を記入してください。不明な場合は、"最大収容可能パレット数"から、現在の庫腹状況を考慮して記載してください。<br>
                (30)は調査時点での手荷役分を除いた在庫量を記載してください。<br>
                (31)T11・T12以外のサイズを使用している場合は、縦・横サイズをご記入ください。(注)T11：110センチ×110センチ T12：120センチ×100センチ<br>
                (32)※天井までの段積後の１山の高さではなく、1ﾊﾟﾚｯﾄでの商品部分の高さをお答えください。
                <v-img src="/image/pallet.png" max-width="202" class="mt-2"></v-img>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{}">
              <thead>
                <tr>
                  <th :width="100"></th>
                  <th class="text-center">項目</th>
                  <th class="text-center">数量</th>
                  <th class="text-center">
                    <NumberTextField
                      v-model="weightPerPiece"
                      dense
                      :readonly="true"
                      :rules="[]"
                      :loading="loading"
                      background-color='attention'
                      suffix="kg/1P"
                      :propClass="['input-color-red']"
                    ></NumberTextField>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- 調査時点でのパレット使用枚数 -->
                <tr>
                  <td class="center">
                    (29)
                  </td>
                  <td class="">
                    調査時点でのパレット使用枚数
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.usagePallet"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    枚
                  </td>
                </tr>
                <!-- パレット保管分在庫量 -->
                <tr>
                  <td class="center">
                    (30)
                  </td>
                  <td class="">
                    パレット保管分在庫量
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.stockPallet"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    トン
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="no-hover-effect table-border"
          >
            <template v-slot:body="{}">
              <thead>
                <tr>
                  <th :width="100" rowspan="2"></th>
                  <th rowspan="2"></th>
                  <th class="text-center" colspan="2">T11</th>
                  <th class="text-center" colspan="2">T12</th>
                  <th class="text-center" colspan="3">T11・T12以外</th>
                </tr>
                <tr>
                  <th class="text-center">有無</th>
                  <th class="text-center">枚数</th>
                  <th class="text-center">有無</th>
                  <th class="text-center">枚数</th>
                  <th class="text-center">縦</th>
                  <th class="text-center">横</th>
                  <th class="text-center">枚数</th>
                </tr>
              </thead>
              <tbody>
                <!-- 使用パレット -->
                <tr>
                  <td class="center">
                    (31)
                  </td>
                  <td class="">
                    使用パレット
                  </td>
                  <td class="center">
                    <v-select
                      v-model="answerData.usingT11"
                      :items="[
                        { text: '×', value: 0 },
                        { text: '○', value: 1 },
                      ]"
                      dense
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[]"
                      :loading="loading"
                      background-color='input'
                      @change="$refs.form.validate()"
                    ></v-select>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.numT11"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :rules="[requiredIf(() => (answerData.usingT11)), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <v-select
                      v-model="answerData.usingT12"
                      :items="[
                        { text: '×', value: 0 },
                        { text: '○', value: 1 },
                      ]"
                      dense
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[]"
                      :loading="loading"
                      background-color='input'
                      @change="$refs.form.validate()"
                    ></v-select>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.numT12"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :rules="[requiredIf(() => (answerData.usingT12)), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.palletVertical"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => (
                                (answerData.palletHorizontal != null && answerData.palletHorizontal != '') || 
                                  (answerData.numOther != null && answerData.numOther != ''))), 
                                maxValLocaleJP(9999999), 
                                minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                      suffix="cm"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.palletHorizontal"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :clearable="false"
                      :rules="[requiredIf(() => (
                                (answerData.palletVertical != null && answerData.palletVertical != '') || 
                                  (answerData.numOther != null && answerData.numOther != ''))), 
                                maxValLocaleJP(9999999), 
                                minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                      suffix="cm"
                    ></NumberTextField>
                  </td>
                  <td class="center">
                    <NumberTextField
                      v-model="answerData.numOther"
                      dense
                      :orgRoundStep="1" 
                      :readonly="attribute == 0 && answerData.status >= 3"
                      :rules="[requiredIf(() => (
                                (answerData.palletVertical != null && answerData.palletVertical != '') || 
                                  (answerData.palletHorizontal != null && answerData.palletHorizontal != ''))), 
                                maxValLocaleJP(9999999), 
                                minValLocaleJP(0)]"
                      :loading="loading"
                      background-color='input'
                    ></NumberTextField>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6">
          <NumberTextField
            label="(32)最大積付高さ(1PLあたり)"
            v-model="answerData.stowageHeight"
            dense
            :orgRoundStep="1" 
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
            :loading="loading"
            background-color='input'
            suffix="センチ／1ﾊﾟﾚｯﾄあたり"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <NumberTextField
            label="(参考)1ﾊﾟﾚｯﾄ積付容積"
            v-model="stowageCapacity"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='attention'
            suffix="㎡"
            :propClass="['input-color-red']"
          ></NumberTextField>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field
            label="(参考)比重"
            :value="weight | formatToFixed(3)"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
            background-color='attention'
            class="right-input input-color-red"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- (33)貴事業所の業績動向について -->
      <v-row>
        <v-col>
          <h3>(33)貴事業所の業績動向について</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6">
          ①現在の業績動向に関してどのように感じられていますか？
        </v-col>
        <v-col cols="auto" sm="auto" md="auto" lg="auto">
          <v-radio-group 
            v-model="answerData.trendsNow"
            dense
            row
            :readonly="attribute == 0 && answerData.status >= 3"
            :rules="[]"
            :loading="loading"
            class="ml-5 mt-0 pt-0"
            background-color='input'
          >
            <v-radio
              label="良い"
              :value="0"
            ></v-radio>
            <v-radio
              label="悪い"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6">
          ②次年度以降の業績動向はどのようになると思いますか？
        </v-col>
        <v-col cols="auto" sm="auto" md="auto" lg="auto">
          <v-radio-group 
            v-model="answerData.trendsFuture"
            dense
            row
            :readonly="attribute == 0 && answerData.status >= 3"
            :rules="[]"
            :loading="loading"
            class="ml-5 mt-0 pt-0"
            background-color='input'
          >
            <v-radio
              label="良くなる"
              :value="0"
            ></v-radio>
            <v-radio
              label="悪くなる"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="（業績動向に対する自由意見）"
            v-model="answerData.trendsRemarks"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[]"
            :maxLength="1000"
            :counter="1000"
            :loading="loading"
            background-color='input'
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- 追加設問 -->
      <v-row v-if="qaList.length > 0">
        <v-col>
          <h3>その他の追加設問</h3>
        </v-col>
      </v-row>
      <v-row v-for="(qaData, index) in qaList" :key="qaData.qaSid">
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-label>
            <span class="text-pre-wrap" v-text="(index+1)+'.'+qaData.question "></span>
          </v-label>
          <v-textarea
            v-model="qaData.answer"
            dense
            :readonly="attribute == 0 && answerData.status >= 3"
            :clearable="false"
            :rules="[]"
            :maxLength="100"
            :counter="100"
            :loading="loading"
            rows="2"
            background-color='input'
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row>
        <v-col>
          <h3>このたびはお忙しいところご回答いただき、まことにありがとうございました。</h3>
        </v-col>
      </v-row>

      <v-row class="justify-space-between">
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="saveData()" :disabled="loading || (attribute == 0 && answerData.status >= 3)" >一時保存</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn 
            class="btn_font primary" 
            width="120" 
            v-on:click="registData()" 
            :disabled="loading || (attribute == 0 && (answerData.status >= 3 || !isBetweenDate(dest.ans_period_from, dest.and_period_to)))" >回答</v-btn>
        </v-col>
        <v-col v-if="attribute != 0 && answerData.status >= 3" cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="sendBackData()" :disabled="loading" >差戻し</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
        </v-col>
      </v-row>

    </v-container>

    <!-- modal -->
    <modal name="import-modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="200px" width="80%" id="vm--modal-import">
      <div class="modal-wrap">
        <div class="d-flex modal-header mt-8 pb-8 justify-space-between">
          <h2>回答票インポート</h2>
          <v-icon x-large v-on:click="hideImport()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <v-container>
            <v-row class="">
              <v-col cols="12">
                回答票Excelを選択してください。
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="8">
                <v-file-input 
                  label="回答票Excelファイル" 
                  v-model="excelFile" 
                  dense 
                  accept=".xlsx"
                  truncate-length="30"
                  prepend-icon="mdi-import"
                ></v-file-input>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-btn 
                  class="btn_font primary mr-2" 
                  width="150" 
                  v-on:click="importExcel()"
                  :disabled="loading || (excelFile == null)" >
                  回答票インポート
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </modal>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import Message from "../Lib/Message";
import Util from '../Lib/Util'
import DatePicker from "./common/DatePicker.vue";
import NumberTextField from "./common/Number_text_field.vue";
import AlertSnackbar from "./common/AlertSnackbar.vue";
import Mixin from "../mixins/const.js";
import XlsxImport from '../mixins/excel_import';
import * as XLSX from "xlsx";

import FactFindingAnswer from "../model/factFindingAnswer";
import FactFindingDest from "../model/factFindingDest";

export default {
  components: {
    DatePicker,
    NumberTextField,
    AlertSnackbar,
  },

  mixins: [Mixin, XlsxImport],

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    // ログインユーザ属性
    attribute: 0,

    // オープンするパネル(index)
    openPanel: 0,

    // 調査ID
    targetSurveyId: '',
    // 回答会員番号
    targetMemberNo: '',
    // 回答ID
    editId: '',
    // 回答
    mode: '',

    // 回答受付期間変更
    editAnsPeriodMode: false,

    // 会員データ
    memberData: {},

    // 回答データ
    answerData: {
      sid: '',
      surveyId: '',
      memberNo: '',
      targetMonthFrom: "",
      targetMonthTo: "",
      prefId: null,
      companyName: "",
      officeName: "",
      address: "",
      departmentName: "",
      responderName: "",
      tel: "",
      email: "",
      // 庫腹・受給
      capacityReceipt: [
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
      ],
      facilityTon: 0.0,
      // 事業データ
      industry: [],
      industryOther: '',
      // 売上
      sales: [
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
        {
          valueGeneral: null,
          valueMeasure: null,
          total: null,
        },
      ],
      // 人員・賃金
      staffWages: {
        // 自社
        company: [
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
            aveAge: null,
            aveContinuous: null,
          },
        ],
        // 協力会社
        partner: [
          {
            numPerDay: null,
            aveIncome: null,
          },
          {
            numPerDay: null,
            aveIncome: null,
          },
        ],
      },
      // 業務損益
      profitAndLoss: null,
      // その他1
      contractElectricity: null,
      usageElectricity: null,
      paymentElectricity: null,
      unitPriceElectricity: null,
      rateElectricity: null,
      numDock: null,
      refrigerator: null,
      refrigeratorTemperature: null,
      numClient: null,
      numNewClient: null,
      // その他2
      usagePallet: null,
      stockPallet: null,
      weightPerPiece: null,
      usingT11: null,
      usingT12: null,
      palletVertical: null,
      palletHorizontal: null,
      numT11: null,
      numT12: null,
      numOther: null,
      stowageVertical: null,
      stowageHorizontal: null,
      stowageHeight: null,
      stowageCapacity: null,
      weight: null,
      // 業績動向
      trendsNow: null,
      trendsFuture: null,
      trendsRemarks: '',
      status: 0,
    },
    dest: {},
    qaList: [],

    // 事業所会員データ
    officeData: {
      companyName: "",
      officeName: "",
      address: "",
      bldg: "",
      volume: null,
    },

    // 前年データ
    previous: {
      previousUnitPriceElectricity: null,
      previousRateElectricity: null,
    },

    // バリデーション用 回答フラグ
    saveRegist: false,

    // file
    excelFile: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyId = this.$store.getters.getTargetSurveyId;
    this.targetMemberNo = this.$store.getters.getTargetMemberNo;
    this.editId = '';

    this.answerData.surveyId = this.targetSurveyId;
    this.answerData.memberNo = this.targetMemberNo;

    this.attribute = this.$store.getters.getAttribute;  // 属性

    // 回答データの取得
    this.getData(this.targetSurveyId, this.targetMemberNo);
  },

  mounted: async function () {

  },

  methods: {
    async getData(surveyId, memberNo) {
      Debug.log('function[getData]');
      this.loading = true;

      try {
        const res = await FactFindingAnswer.getData(surveyId, memberNo);
        Debug.log(res.data);
        if (res.data) {
          // 事業所会員データ
          this.officeData = res.data.officeData;
          Debug.log(this.officeData);
          // 宛先と回答受付期間
          this.dest = res.data.dest;

          // 回答データ
          if(Object.keys(res.data.answer).length > 0) {
            this.mode = 'edit';
            this.answerData = res.data.answer;
            Debug.log(this.answerData);
          } else {
            // 新規の場合初期値を設定
            this.mode = 'new';
            this.answerData.prefId = this.officeData.prefId;                          // 都道府県
            this.answerData.companyName = this.officeData.companyName;                // 会社名
            this.answerData.officeName = this.officeData.officeName;                  // 事業所名
            this.answerData.address = this.officeData.address + this.officeData.bldg; // 所在地
            this.answerData.capacityReceipt[0].total = this.officeData.volume;        // 所管容積
          }

          // 追加設問
          this.qaList = res.data.qaList;

          // 前年回答データ
          this.previous = res.data.previous;
        }
      } catch (error) {
        // alert('情報を取得できませんでした。')
        Message.err(error, "情報を取得できませんでした");
      }

      this.loading = false;
    },

    // 回答一時保存
    async saveData() {
      if (!this.$refs.form.validate()) {
        // 回答エリアTOPまでスクロール
        document.getElementById("ansAreaTop").scrollIntoView(true);
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("一時保存します。よろしいですか？")) return;
      // dateをcomputedの値で更新
      this.updateValue();
      // 一時保存
      this.answerData.status = 1;

      this.loading = true;
      try {
        const res = await FactFindingAnswer.setFactFindingAnswerData(this.answerData, this.qaList, this.mode);
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("回答を一時保存しました。");

          this.$router.back();
        }

      } catch (error) {
        Message.err(error, "一時保存できませんでした");
      }

      this.loading = false;
    },

    // 回答
    async registData() {
      this.saveRegist = true;
      if (!this.$refs.form.validate()) {
        // 回答エリアTOPまでスクロール
        document.getElementById("ansAreaTop").scrollIntoView(true);
        alert("入力に誤りがあります。内容をご確認ください。");
        this.saveRegist = false;
        return;
      }

      if (!confirm("回答を送信します。送信後は回答を変更できません。よろしいですか？")) return;
      // dateをcomputedの値で更新
      this.updateValue();
      // 回答済
      this.answerData.status = 3;

      this.loading = true;
      try {
        const res = await FactFindingAnswer.setFactFindingAnswerData(this.answerData, this.qaList, this.mode);
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("送信しました。");

          this.$router.back();
        }

      } catch (error) {
        Message.err(error, "送信できませんでした");
      }

      this.loading = false;
    },

    // 差戻し
    async sendBackData() {
      if (!this.$refs.form.validate()) {
        // 回答エリアTOPまでスクロール
        document.getElementById("ansAreaTop").scrollIntoView(true);
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("回答を保存して差戻します。差戻し後は事業所が回答を変更できるようになります。よろしいですか？")) return;
      // dateをcomputedの値で更新
      this.updateValue();
      // 一時保存中
      this.answerData.status = 1;

      this.loading = true;
      try {
        const res = await FactFindingAnswer.setFactFindingAnswerData(this.answerData, this.qaList, this.mode);
        // Debug.log(res);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("差戻しました。");

          this.$router.back();
        }

      } catch (error) {
        Message.err(error, "差戻しできませんでした");
      }

      this.loading = false;
    },

    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;
      this.$router.back();
    },


    // キャンセル
    cancelAnsPeriod() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      //this.search();
      this.editAnsPeriodMode = false;
    },
    // 回答データ編集
    editAnsPeriod() {
      this.editAnsPeriodMode = true;
    },
    // 保存
    async registAnsPeriod() {
      if (!confirm("回答受付期間を保存します。よろしいですか？")) return;

      this.loading = true;
      try {
        const res = await FactFindingDest.setAnsPeriod(this.dest);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          this.loading = false;
          return;
        } else {
          alert("保存しました。");
        }

      } catch (error) {
        Message.err(error, "保存できませんでした");
      }
      this.loading = false;
      this.editAnsPeriodMode = false;

      // データを再取得
      //this.search();
    },

    // 数字のみ許可
    numOnlyInput(value, minus=false, decimal=false, decimalNum=0) {
      let tmpValue = Util.numOnly(value, minus, decimal, decimalNum)
      return tmpValue;
    },

    // 数字、ハイフン-のみ許可
    telOnlyInput(value, minus=false, decimal=false, decimalNum=0) {
      let tmpValue = Util.numOnly(value, minus, decimal, decimalNum)
      return tmpValue;
    },

    // dateをcomputedの値で更新、「うち容積建」の未入力を0に変換
    updateValue() {
      this.answerData.capacityReceipt[0].valueGeneral = this.volumeValueGeneral;  // 所管容積 一般保管
      this.answerData.facilityTon = this.facilityTon;                             // 設備トン
      this.answerData.capacityReceipt[1].total = this.inValueTotal;               // 年間入庫数量 合計
      this.answerData.capacityReceipt[2].total = this.outValueTotal;              // 年間出庫数量 合計
      this.answerData.capacityReceipt[3].total = this.prevTotal;                  // 前月繰越数量 合計
      this.answerData.capacityReceipt[4].total = this.interTotal;                 // 中間繰越数量 合計
      this.answerData.sales[0].total = this.storageValueTotal;                    // 年間保管料収入 合計
      this.answerData.sales[1].total = this.cargoValueTotal;                      // 年間荷役料収入 合計
      this.answerData.sales[2].total = this.workValueTotal;                       // 年間その他作業料 合計
      this.answerData.sales[3].total = this.chargeValueTotal;                     // 年間その他手数料 合計
      this.answerData.sales[5].total = this.processingValueTotal;                 // 年間流通加工(粗利) 合計
      this.answerData.sales[6].total = this.transportValueTotal;                  // 年間荷役料収入 合計
      this.answerData.sales[7].valueGeneral = this.incomeValueGeneral;            // 収入合計 一般保管
      this.answerData.sales[7].valueMeasure = this.incomeValueMeasure;            // 収入合計 容積建
      this.answerData.sales[7].total = this.incomeValueTotal;                     // 収入合計 合計
      this.answerData.staffWages.company[5].numPerDay = this.employeeTotalNum;    // 合計人数
      this.answerData.staffWages.company[5].aveIncome = this.employeeTotalAveIncome;  // 合計平均年収
      this.answerData.unitPriceElectricity = this.unitPriceElectricity;            // KWH単価 今回
      this.answerData.rateElectricity = this.rateElectricity;                      // 契約電力有効率 今回
      this.answerData.weightPerPiece = this.weightPerPiece;                        // 重量/1パレット
      this.answerData.stowageVertical = this.stowageVertical;                      // 積付容積計算用 縦
      this.answerData.stowageHorizontal = this.stowageHorizontal;                  // 積付容積計算用 横
      this.answerData.stowageCapacity = this.stowageCapacity;                      // 1パレット積付容積
      this.answerData.weight = this.weight;                                        // 比重

      // 「うち容積建」の未入力を0に変換
      if(this.answerData.capacityReceipt[0].valueMeasure == '' || 
          this.answerData.capacityReceipt[0].valueMeasure == null) {
        this.answerData.capacityReceipt[0].valueMeasure = 0;
      }
      if(this.answerData.capacityReceipt[1].valueMeasure == '' || 
          this.answerData.capacityReceipt[1].valueMeasure == null) {
        this.answerData.capacityReceipt[1].valueMeasure = 0;
      }
      if(this.answerData.capacityReceipt[2].valueMeasure == '' || 
          this.answerData.capacityReceipt[2].valueMeasure == null) {
        this.answerData.capacityReceipt[2].valueMeasure = 0;
      }
    },

    /** 回答票Excelインポート */
    showImport () {
      Debug.log('function[showImport]');

      this.$modal.show('import-modal-dialog');
    },
    hideImport : function () {
      Debug.log('function[hideImport]');
      this.$modal.hide('import-modal-dialog');
    },

    getExt(fileName) {
      var pos = fileName.lastIndexOf('.');
      if (pos === -1) return '';
      return fileName.slice(pos + 1);
    },

    importExcel() {
      const e = this.excelFile;
      Debug.log2('file', e);
      Debug.log2('ext', this.getExt(e.name));
      if(this.getExt(e.name) !== 'xlsx'){
        alert("xlsx形式のファイルを指定してください。");
        return;
      }

      if (!confirm("回答票Excelの入力値をコピーします。よろしいですか？")) return;

      var reader = new FileReader();
      reader.onload = function (e) {
        var unit8 = new Uint8Array(e.target.result);

        const workbook = XLSX.read(unit8, {type: "array"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);

        Debug.log2('rows', rows);

        // ===== データコピー =====
        // 基礎情報
        let tmpTargetYear = this.getCellValue(sheet, 'I10', 'v', true);
        let tmpTargetMonth = this.getCellValue(sheet, 'K10', 'v', true);
        if((tmpTargetYear != null) &&
            (tmpTargetMonth != null) &&
            (tmpTargetYear.toString().length == 4) &&
            (tmpTargetMonth.toString().length <= 2)) {
          this.$set(this.answerData, "targetMonthFrom", tmpTargetYear+'-'+tmpTargetMonth.toString().padStart(2, '0'));    // 調査対象期間 開始
        } else {
          this.$set(this.answerData, "targetMonthFrom", null);                                                            // 調査対象期間 開始
        }
        tmpTargetYear = this.getCellValue(sheet, 'N10', 'v', true);
        tmpTargetMonth = this.getCellValue(sheet, 'P10', 'v', true);
        if((tmpTargetYear != null) &&
            (tmpTargetMonth != null) &&
            (tmpTargetYear.toString().length == 4) &&
            (tmpTargetMonth.toString().length <= 2)) {
          this.$set(this.answerData, "targetMonthTo", tmpTargetYear+'-'+tmpTargetMonth.toString().padStart(2, '0'));      // 調査対象期間 終了
        } else {
          this.$set(this.answerData, "targetMonthTo", null);                                                              // 調査対象期間 終了
        }
        this.$set(this.answerData, "departmentName", this.getCellValue(sheet, 'D14', 'w'));             // 部署名
        this.$set(this.answerData, "responderName", this.getCellValue(sheet, 'G14', 'w'));              // 担当者名
        this.$set(this.answerData, "tel", this.getCellValue(sheet, 'J14', 'w'));                        // 電話
        this.$set(this.answerData, "email", this.getCellValue(sheet, 'N14', 'w'));                      // メール
        // １．庫腹・受給
        this.$set(this.answerData.capacityReceipt[0], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G18', 'v', true), 1));      // 所管容積 うち容積建
        this.$set(this.answerData.capacityReceipt[1], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F19', 'v', true), 1));      // 年間入庫数量 うち一般保管
        this.$set(this.answerData.capacityReceipt[1], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G19', 'v', true), 1));      // 年間入庫数量 うち容積建
        this.$set(this.answerData.capacityReceipt[2], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F20', 'v', true), 1));      // 年間出庫数量 うち一般保管
        this.$set(this.answerData.capacityReceipt[2], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G20', 'v', true), 1));      // 年間出庫数量 うち容積建
        this.$set(this.answerData.capacityReceipt[3], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F21', 'v', true), 1));      // 前月繰越数量 うち一般保管
        this.$set(this.answerData.capacityReceipt[4], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F22', 'v', true), 1));      // 中間繰越数量 うち一般保管
        // ２．売上
        // 貴社該当の事業
        let tmpIndustry = [];
        if(this.getCellValue(sheet, 'V25', 'w') == "TRUE") {
          tmpIndustry.push("1");
        }
        if(this.getCellValue(sheet, 'V26', 'w') == "TRUE") {
          tmpIndustry.push("2");
        }
        if(this.getCellValue(sheet, 'V27', 'w') == "TRUE") {
          tmpIndustry.push("3");
        }
        if(this.getCellValue(sheet, 'V28', 'w') == "TRUE") {
          tmpIndustry.push("4");
        }
        if(this.getCellValue(sheet, 'V29', 'w') == "TRUE") {
          tmpIndustry.push("5");
        }
        if(this.getCellValue(sheet, 'V30', 'w') == "TRUE") {
          tmpIndustry.push("6");
        }
        this.$set(this.answerData, "industry", tmpIndustry);                                                                    // 貴社該当の事業
        this.$set(this.answerData, "industryOther", this.getCellValue(sheet, 'P25', 'w'));                                      // その他
        this.$set(this.answerData.sales[0], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F28', 'v', true), 1));      // 年間保管料収入 うち一般保管
        this.$set(this.answerData.sales[0], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G28', 'v', true), 1));      // 年間保管料収入 うち容積建
        this.$set(this.answerData.sales[1], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F29', 'v', true), 1));      // 年間荷役料収入 うち一般保管
        this.$set(this.answerData.sales[1], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G29', 'v', true), 1));      // 年間荷役料収入 うち容積建
        this.$set(this.answerData.sales[2], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F30', 'v', true), 1));      // 年間その他作業料 うち一般保管
        this.$set(this.answerData.sales[2], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G30', 'v', true), 1));      // 年間その他作業料 うち容積建
        this.$set(this.answerData.sales[3], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F31', 'v', true), 1));      // 年間その他手数料 うち一般保管
        this.$set(this.answerData.sales[3], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G31', 'v', true), 1));      // 年間その他手数料 うち容積建
        this.$set(this.answerData.sales[4], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F32', 'v', true), 1));      // 年間凍結料収入 うち一般保管
        this.$set(this.answerData.sales[5], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F33', 'v', true), 1));      // 年間流通加工(粗利) うち一般保管
        this.$set(this.answerData.sales[5], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G33', 'v', true), 1));      // 年間流通加工(粗利) うち容積建
        this.$set(this.answerData.sales[6], "valueGeneral", Util.orgRound(this.getCellValue(sheet, 'F34', 'v', true), 1));      // 年間利用運送(粗利) うち一般保管
        this.$set(this.answerData.sales[6], "valueMeasure", Util.orgRound(this.getCellValue(sheet, 'G34', 'v', true), 1));      // 年間利用運送(粗利) うち容積建
        // ３．人員・賃金
        // 自社従業員数・平均年収(作業専任職)
        this.$set(this.answerData.staffWages.company[0], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G40', 'v', true), 10));         // パート・アルバイト 人数
        this.$set(this.answerData.staffWages.company[0], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H40', 'v', true), 1));          // パート・アルバイト 平均年収
        this.$set(this.answerData.staffWages.company[1], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G41', 'v', true), 10));         // 作業専任職 人数
        this.$set(this.answerData.staffWages.company[1], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H41', 'v', true), 1));          // 作業専任職 平均年収
        this.$set(this.answerData.staffWages.company[1], "aveAge", Util.orgRound(this.getCellValue(sheet, 'I41', 'v', true), 10));            // 作業専任職 平均年齢
        this.$set(this.answerData.staffWages.company[1], "aveContinuous", Util.orgRound(this.getCellValue(sheet, 'J41', 'v', true), 10));     // 作業専任職 平均勤続年数
        // 自社従業員数・平均年収(事務職)
        this.$set(this.answerData.staffWages.company[2], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G42', 'v', true), 10));         // パート・アルバイト 人数
        this.$set(this.answerData.staffWages.company[2], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H42', 'v', true), 1));          // パート・アルバイト 平均年収
        this.$set(this.answerData.staffWages.company[3], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G43', 'v', true), 10));         // 事務職 人数
        this.$set(this.answerData.staffWages.company[3], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H43', 'v', true), 1));          // 事務職 平均年収
        this.$set(this.answerData.staffWages.company[3], "aveAge", Util.orgRound(this.getCellValue(sheet, 'I43', 'v', true), 10));            // 事務職 平均年齢
        this.$set(this.answerData.staffWages.company[3], "aveContinuous", Util.orgRound(this.getCellValue(sheet, 'J43', 'v', true), 10));     // 事務職 平均勤続年数
        this.$set(this.answerData.staffWages.company[4], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G44', 'v', true), 10));         // 管理職・役員・その他 人数
        this.$set(this.answerData.staffWages.company[4], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H44', 'v', true), 1));          // 管理職・役員・その他 平均年収
        // 協力会社従業員数
        this.$set(this.answerData.staffWages.partner[0], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G48', 'v', true), 10));         // 下請・派遣現場要員 人数
        this.$set(this.answerData.staffWages.partner[0], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H48', 'v', true), 1));          // 下請・派遣現場要員 平均日給
        this.$set(this.answerData.staffWages.partner[1], "numPerDay", Util.orgRound(this.getCellValue(sheet, 'G49', 'v', true), 10));         // 下請・派遣事務職 人数
        this.$set(this.answerData.staffWages.partner[1], "aveIncome", Util.orgRound(this.getCellValue(sheet, 'H49', 'v', true), 1));          // 下請・派遣事務職 平均日給
        // ４．業務損益
        this.$set(this.answerData, "profitAndLoss", Util.orgRound(this.getCellValue(sheet, 'F53', 'v', true), 1));              // 業務損益(年間)
        // ５．その他-1
        this.$set(this.answerData, "contractElectricity", Util.orgRound(this.getCellValue(sheet, 'F58', 'v', true), 1));        // 契約電力
        this.$set(this.answerData, "usageElectricity", Util.orgRound(this.getCellValue(sheet, 'F59', 'v', true), 1));           // 年間電力使用量
        this.$set(this.answerData, "paymentElectricity", Util.orgRound(this.getCellValue(sheet, 'F60', 'v', true), 1));         // 年間電力支払金額
        this.$set(this.answerData, "numDock", Util.orgRound(this.getCellValue(sheet, 'F62', 'v', true), 1));                    // トラック接車可能台数
        this.$set(this.answerData, "refrigerator", this.getCellValue(sheet, 'F63', 'w') == '○' ? 1 : 0);                        // １階荷捌室低温化
        this.$set(this.answerData, "refrigeratorTemperature", Util.orgRound(this.getCellValue(sheet, 'F64', 'v', true), 10));   // １階荷捌設定温度
        this.$set(this.answerData, "numClient", Util.orgRound(this.getCellValue(sheet, 'G66', 'v', true), 1));                  // 調査時点での取引荷主企業数
        this.$set(this.answerData, "numNewClient", Util.orgRound(this.getCellValue(sheet, 'G67', 'v', true), 1));               // 調査時点での過去１年間の新規取引荷主企業数
        // ５．その他-2
        this.$set(this.answerData, "usagePallet", Util.orgRound(this.getCellValue(sheet, 'G70', 'v', true), 1));                // 調査時点でのパレット使用枚数
        this.$set(this.answerData, "stockPallet", Util.orgRound(this.getCellValue(sheet, 'G71', 'v', true), 1));                // パレット保管分在庫量
        this.$set(this.answerData, "usingT11", this.getCellValue(sheet, 'E74', 'w') == '○' ? 1 : 0);                            // 使用パレットT11
        this.$set(this.answerData, "usingT12", this.getCellValue(sheet, 'F74', 'w') == '○' ? 1 : 0);                            // 使用パレットT12
        let tmpVal = this.getCellValue(sheet, 'G74', 'v', true);
        if((tmpVal != null) &&
            (tmpVal != 0)) {
          this.$set(this.answerData, "palletVertical", Util.orgRound(tmpVal, 1));                                               // T11・T12以外 縦
        } else {
          this.$set(this.answerData, "palletVertical", null);                                                                   // T11・T12以外 縦
        }
        tmpVal = this.getCellValue(sheet, 'H74', 'v', true);
        if((tmpVal != null) &&
            (tmpVal != 0)) {
          this.$set(this.answerData, "palletHorizontal", Util.orgRound(tmpVal, 1));                                             // T11・T12以外 横
        } else {
          this.$set(this.answerData, "palletHorizontal", null);                                                                 // T11・T12以外 横
        }
        this.$set(this.answerData, "numT11", Util.orgRound(this.getCellValue(sheet, 'E75', 'v', true), 1));                     // T11 枚数
        this.$set(this.answerData, "numT12", Util.orgRound(this.getCellValue(sheet, 'F75', 'v', true), 1));                     // T12 枚数
        this.$set(this.answerData, "numOther", Util.orgRound(this.getCellValue(sheet, 'G75', 'v', true), 1));                   // T11・T12以外 枚数
        this.$set(this.answerData, "stowageHeight", Util.orgRound(this.getCellValue(sheet, 'F77', 'v', true), 1));              // 最大積付高さ(1PLあたり)
        // 貴事業所の業績動向について
        let tmpTrends = this.getCellValue(sheet, 'V85', 'w');
        this.$set(this.answerData, "trendsNow", tmpTrends == '1' ? 0 : tmpTrends == '2' ? 1 : null);                            // 現在の業績動向
        tmpTrends = this.getCellValue(sheet, 'V87', 'w');
        this.$set(this.answerData, "trendsFuture", tmpTrends == '1' ? 0 : tmpTrends == '2' ? 1 : null);                         // 次年度以降の業績動向
        this.$set(this.answerData, "trendsRemarks", this.getCellValue(sheet, 'C89', 'w'));                                      // 業績動向に対する自由意見

        // 追加設問
        // if(this.qaList.length > 0) {
        //   this.$set(this.qaList[0], 'answer', this.getCellValue(sheet, 'K95', 'w'));
        // }
        let col = 10;
        let row = 94;
        for(let i = 0 ; i < this.qaList.length ; i++) {
          this.$set(this.qaList[i], 'answer', this.getCellValue(sheet, this.getEncode_cell(col, row), 'w'));

          row++;
          row++;
          row++;
          row++;
        }
        
      }.bind(this);
      reader.readAsArrayBuffer(e);

      this.hideImport();
    },
  },

  computed :{
    // 所管容積 一般保管
    volumeValueGeneral() {
      return this.answerData.capacityReceipt[0].total - this.answerData.capacityReceipt[0].valueMeasure;
    },
    // 設備トン
    facilityTon() {
      return Util.orgRound((this.answerData.capacityReceipt[0].total * 0.4), 10);
    },
    // 年間入庫数量 合計
    inValueTotal() {
      return Number(this.answerData.capacityReceipt[1].valueGeneral) + Number(this.answerData.capacityReceipt[1].valueMeasure);
    },
    // 年間出庫数量 合計
    outValueTotal() {
      return Number(this.answerData.capacityReceipt[2].valueGeneral) + Number(this.answerData.capacityReceipt[2].valueMeasure);
    },
    // 前月繰越数量 合計
    prevTotal() {
      return this.answerData.capacityReceipt[3].valueGeneral !== null ?  this.answerData.capacityReceipt[3].valueGeneral : 0;
    },
    // 中間繰越数量 合計
    interTotal() {
      return this.answerData.capacityReceipt[4].valueGeneral !== null ? this.answerData.capacityReceipt[4].valueGeneral : 0;
    },
    // 年間保管料収入 合計
    storageValueTotal() {
      return Number(this.answerData.sales[0].valueGeneral) + Number(this.answerData.sales[0].valueMeasure);
    },
    // 年間荷役料収入 合計
    cargoValueTotal() {
      return Number(this.answerData.sales[1].valueGeneral) + Number(this.answerData.sales[1].valueMeasure);
    },
    // 年間その他作業料 合計
    workValueTotal() {
      return Number(this.answerData.sales[2].valueGeneral) + Number(this.answerData.sales[2].valueMeasure);
    },
    // 年間その他手数料 合計
    chargeValueTotal() {
      return Number(this.answerData.sales[3].valueGeneral) + Number(this.answerData.sales[3].valueMeasure);
    },
    // 年間流通加工(粗利) 合計
    processingValueTotal() {
      return Number(this.answerData.sales[5].valueGeneral) + Number(this.answerData.sales[5].valueMeasure);
    },
    // 年間利用運送(粗利) 合計
    transportValueTotal() {
      return Number(this.answerData.sales[6].valueGeneral) + Number(this.answerData.sales[6].valueMeasure);
    },
    // 収入合計 一般保管
    incomeValueGeneral() {
      return Number(this.answerData.sales[0].valueGeneral) + Number(this.answerData.sales[1].valueGeneral) + Number(this.answerData.sales[2].valueGeneral) + Number(this.answerData.sales[3].valueGeneral) + Number(this.answerData.sales[4].valueGeneral);
    },
    // 収入合計 容積建
    incomeValueMeasure() {
      return Number(this.answerData.sales[0].valueMeasure) + Number(this.answerData.sales[1].valueMeasure) + Number(this.answerData.sales[2].valueMeasure) + Number(this.answerData.sales[3].valueMeasure) + Number(this.answerData.sales[4].valueMeasure);
    },
    // 収入合計 合計
    incomeValueTotal() {
      return Number(this.incomeValueGeneral) + Number(this.incomeValueMeasure);
    },
    // 合計人数
    employeeTotalNum() {
      let sum = 0;
      this.answerData.staffWages.company.forEach((e, index) => {
        if(index != 5) {
          sum += Number(e.numPerDay);
        }
      });
      // IEEE 754でズレるので少数第2位で四捨五入する
      return Util.orgRound(sum, 10);
    },
    // 合計平均年収
    employeeTotalAveIncome() {
      let sum = 0;
      this.answerData.staffWages.company.forEach((e, index) => {
        if(index != 5) {
          sum += Number(e.aveIncome);
        }
      });
      return sum;
    },
    // KWH単価 今回
    unitPriceElectricity() {
      let res = 0;
      if(this.answerData.paymentElectricity === null || 
          this.answerData.paymentElectricity == 0 || 
          this.answerData.usageElectricity === null ||
          this.answerData.usageElectricity == 0) {
        return res;
      }

      res = this.answerData.paymentElectricity * 1000 / this.answerData.usageElectricity;
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 契約電力有効率 今回
    rateElectricity() {
      let res = 0;
      if(this.answerData.usageElectricity === null || 
          this.answerData.usageElectricity == 0 || 
          this.answerData.contractElectricity === null ||
          this.answerData.contractElectricity == 0) {
        return res;
      }

      res = this.answerData.usageElectricity / (this.answerData.contractElectricity * 24 * 365) * 100;
      // 少数第2位で四捨五入
      return Util.orgRound(res, 10);
    },
    // 重量/1パレット
    weightPerPiece() {
      let res = 0;
      if(this.answerData.stockPallet === null || 
          this.answerData.stockPallet == 0 || 
          this.answerData.usagePallet === null ||
          this.answerData.usagePallet == 0) {
        return res;
      }

      res = this.answerData.stockPallet / this.answerData.usagePallet * 1000;
      // 少数第1位で四捨五入
      return Util.orgRound(res, 1);
    },
    // 積付容積計算用 縦
    stowageVertical() {
      let res = 0;
      if(this.answerData.usingT11 && this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11使用 かつ T12使用 かつ T11・T12以外使用
          // (110+120+T11・T12以外 縦)÷3
          Debug.log('(1) T11使用 かつ T12使用 かつ T11・T12以外使用');
          res = (110 + 120 + Number(this.answerData.palletVertical)) / 3;
        } else {
          // T11使用 かつ T12使用 かつ T11・T12以外未使用
          // (110+120)÷2
          Debug.log('(2) T11使用 かつ T12使用 かつ T11・T12以外未使用');
          res = (110 + 120) / 2;
        }
      } else if(this.answerData.usingT11 && !this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11使用 かつ T12未使用 かつ T11・T12以外使用
          // (110+T11・T12以外 縦)÷2
          Debug.log('(3) T11使用 かつ T12未使用 かつ T11・T12以外使用');
          res = (110 + Number(this.answerData.palletVertical)) / 2;
        } else {
          // T11使用 かつ T12未使用 かつ T11・T12以外未使用
          // T11使用 かつ T12未使用 かつ T11・T12以外未使用
          Debug.log('(4) T11使用 かつ T12未使用 かつ T11・T12以外未使用');
          res = 110
        }
      } else if(!this.answerData.usingT11 && this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11未使用 かつ T12使用 かつ T11・T12以外使用
          // (120+T11・T12以外 縦)÷2
          Debug.log('(5) T11未使用 かつ T12使用 かつ T11・T12以外使用');
          res = (120 + Number(this.answerData.palletVertical)) / 2;
        } else {
          // T11未使用 かつ T12使用 かつ T11・T12以外未使用
          // 120
          Debug.log('(6) T11未使用 かつ T12使用 かつ T11・T12以外未使用');
          res = 120;
        }
      } else {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11未使用 かつ T12未使用 かつ T11・T12以外使用
          // T11・T12以外 縦
          Debug.log('(7) T11未使用 かつ T12未使用 かつ T11・T12以外使用');
          res = Number(this.answerData.palletVertical);
        } else {
          // T11未使用 かつ T12未使用 かつ T11・T12以外未使用
          // 0
          Debug.log('(8) T11未使用 かつ T12未使用 かつ T11・T12以外未使用');
          res = 0;
        }
      }

      // 少数第2位で四捨五入
      Debug.log(Util.orgRound(res, 10));
      return Util.orgRound(res, 10);
    },
    // 積付容積計算用 横
    stowageHorizontal() {
      let res = 0;
      if(this.answerData.usingT11 && this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11使用 かつ T12使用 かつ T11・T12以外使用
          // (110+100+T11・T12以外 横)÷3
          Debug.log('(1) T11使用 かつ T12使用 かつ T11・T12以外使用');
          res = (110 + 100 + Number(this.answerData.palletHorizontal)) / 3;
        } else {
          // T11使用 かつ T12使用 かつ T11・T12以外未使用
          // (110+100)÷2
          Debug.log('(2) T11使用 かつ T12使用 かつ T11・T12以外未使用');
          res = (110 + 100) / 2;
        }
      } else if(this.answerData.usingT11 && !this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11使用 かつ T12未使用 かつ T11・T12以外使用
          // (110+T11・T12以外 横)÷2
          Debug.log('(3) T11使用 かつ T12未使用 かつ T11・T12以外使用');
          res = (110 + Number(this.answerData.palletHorizontal)) / 2;
        } else {
          // T11使用 かつ T12未使用 かつ T11・T12以外未使用
          Debug.log('(4) T11使用 かつ T12未使用 かつ T11・T12以外未使用');
          res = 110
        }
      } else if(!this.answerData.usingT11 && this.answerData.usingT12) {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11未使用 かつ T12使用 かつ T11・T12以外使用
          // (100+T11・T12以外 横)÷2
          Debug.log('(5) T11未使用 かつ T12使用 かつ T11・T12以外使用');
          res = (100 + Number(this.answerData.palletHorizontal)) / 2;
        } else {
          // T11未使用 かつ T12使用 かつ T11・T12以外未使用
          // 100
          Debug.log('(6) T11未使用 かつ T12使用 かつ T11・T12以外未使用');
          res = 100;
        }
      } else {
        if( (this.answerData.palletVertical !== null && this.answerData.palletVertical != '') &&
            (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '') ) {
          // T11未使用 かつ T12未使用 かつ T11・T12以外使用
          // T11・T12以外 横
          Debug.log('(7) T11未使用 かつ T12未使用 かつ T11・T12以外使用');
          res = Number(this.answerData.palletHorizontal);
        } else {
          // T11未使用 かつ T12未使用 かつ T11・T12以外未使用
          // 0
          Debug.log('(8) T11未使用 かつ T12未使用 かつ T11・T12以外未使用');
          res = 0;
        }
      }

      // 少数第2位で四捨五入
      Debug.log(Util.orgRound(res, 10));
      return Util.orgRound(res, 10);
    },
    // 1パレット積付容積
    stowageCapacity() {
      Debug.log('1パレット積付容積');
      let res = 0;
      if( ( this.answerData.usingT11 || this.answerData.usingT12 || ((this.answerData.palletVertical !== null && this.answerData.palletVertical != '') && (this.answerData.palletHorizontal !== null && this.answerData.palletHorizontal != '')) ) &&
          ( this.answerData.stowageHeight !== null &&  this.answerData.stowageHeight != '' ) ) {
        // T11、T12、T11・T12以外のいずれかが使用(入力)されている かつ 最大積付高さが入力されている
        // 積付容積計算用 縦×積付容積計算用 横×最大積付高さ÷1000000
        res = this.stowageVertical * this.stowageHorizontal * this.answerData.stowageHeight / 1000000;
      }

      Debug.log(res);
      Debug.log(Util.orgRound(res, 10));
      return Util.orgRound(res, 10);
    },
    // 比重
    weight() {
      Debug.log('比重');
      let res = 0;
      if( this.weightPerPiece != 0 && this.stowageCapacity != 0 ) {
        // 重量/1パレット、1パレット積付容積が計算されている
        // 重量/1パレット÷(1パレット積付容積×1000)÷0.729
        Debug.log(this.weightPerPiece+'/('+this.stowageCapacity+'* 1000) / 0.729');
        res = this.weightPerPiece / (this.stowageCapacity * 1000) / 0.729;
      }

      Debug.log(res);
      Debug.log(Util.orgRound(res, 1000));
      return Util.orgRound(res, 1000);
    },
  },

  filters: {
    formatToFixed(value, num=0) {
      // 小数点以下を0埋め
      if (!value) return "0";

      return value.toFixed(num);
    },
  },

  watch: {
    'answerData.palletVertical': function(){
      this.$refs.form.validate();
    },
    'answerData.palletHorizontal': function(){
      this.$refs.form.validate();
    },
    'answerData.numOther': function(){
      this.$refs.form.validate();
    },
  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.greenBack {
  background-color: var(--v-input-base);
}

/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
    background-color: transparent !important;
}
.v-data-table >>> .td-rowspan {
  border-bottom: 1px solid #888888 !important;
}

/** 除外テーブル */
.table-border-primary td,
.table-border-primary th 
{
  border: 1px solid var(--v-primary-base);
  color: var(--v-primary-base);
}
.table-border-primary >>> .td-rowspan {
  border-bottom: 1px solid var(--v-primary-base) !important;
}

/** リスト装飾 */
/* 黒丸を表示しない */
ul {
  list-style: none;
}

/** この画面では赤いテキストにしない */
.text-error {
  color: currentcolor !important;
}

/** モーダル */
.modal-wrap {
  height: 100%;
}
.modal-header {
  height: 10%;
}
.modal-header h2 {
  align-items: center;
  display: inline-flex;
}
.modal-body {
  overflow-y: scroll;
  height: 90%;
}
</style>
